import { InMemoryCache } from "shared/providers/apollo/apollo.package";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getBuildings: {
          keyArgs: ["search", "active", "admin", "sortBy", "sortByDir"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        populateBuildings: {
          keyArgs: ["ids", "search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getActivityLog: {
          keyArgs: [
            "activityType",
            "referType",
            "search",
            "userId",
            "from",
            "to",
            "sortBy",
            "sortByDir",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getRecordActivityLog: {
          keyArgs: [
            "activityType",
            "referType",
            "recordId",
            "sortBy",
            "sortByDir",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCompanies: {
          keyArgs: ["search", "companyTypes", "active", "admin", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getFilterableCompanies: {
          keyArgs: ["search", "location", "companyTypes"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getOverlappingCompanies: {
          keyArgs: ["search", "userId", "active", "companyTypes"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getUserCompanies: {
          keyArgs: ["search", "userId", "active", "companyTypes"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getUsers: {
          keyArgs: [
            "search",
            "status",
            "roleIds",
            "buildingIds",
            "primaryBuildingId",
            "fullAccessBuildingUsers",
            "companyIds",
            "primaryCompanyId",
            "fullAccessCompanyUsers",
            "admin",
            "sortBy",
            "sortByDir",
            "orderedByIds",
            "@connection",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getRoles: {
          keyArgs: ["search", "onlyAssignable", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getNewsFeeds: {
          keyArgs: ["search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getDeals: {
          keyArgs: ["categoryIds", "search", "active", "expired"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getIncidents: {
          keyArgs: [
            "buildingIds",
            "statusIds",
            "search",
            "from",
            "to",
            "categoryIds",
            "companyIds",
            "suiteIds",
            "requestedForIds",
            "archived",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getWorkOrders: {
          keyArgs: [
            "search",
            "from",
            "to",
            "buildingIds",
            "statusIds",
            "assigneeIds",
            "categoryIds",
            "companyIds",
            "priorityIds",
            "requestedForIds",
            "closedAtFrom",
            "closedAtTo",
            "archived",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getWorkOrderEquipment: {
          keyArgs: ["search", "workOrderId"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getWorkOrderTemplates: {
          keyArgs: [
            "buildingIds",
            "search",
            "from",
            "to",
            "recordStatuses",
            "categoryIds",
            "companyIds",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getWorkOrderTemplateRelatedRecords: {
          keyArgs: ["search", "workOrderTemplateId"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCois: {
          keyArgs: [
            "statuses",
            "search",
            "from",
            "to",
            "companyTypes",
            "companyIds",
            "createdByIds",
            "archived",
            "expiration",
            "@connection",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getWatchlist: {
          keyArgs: ["search", "companyIds", "requestedForIds"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getGuests: {
          keyArgs: [
            "buildingIds",
            "search",
            "statuses",
            "from",
            "to",
            "hostIds",
            "companyIds",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getVendorAccessList: {
          keyArgs: [
            "buildingIds",
            "search",
            "statuses",
            "from",
            "to",
            "hostIds",
            "companyIds",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getReports: {
          keyArgs: ["search", "type", "status"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getRoomReservations: {
          keyArgs: ["status", "from", "to", "search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getBroadcasts: {
          keyArgs: ["createdByIds", "types", "search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getBroadcastRecipients: {
          keyArgs: ["broadcastId", "search", "take"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getEventAttendance: {
          keyArgs: ["eventId", "search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getSubscriptions: {
          keyArgs: [
            "search",
            "productIds",
            "statuses",
            "requestedForIds",
            "billingPeriod",
            "companyIds",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getEquipmentList: {
          keyArgs: [
            "search",
            "buildingIds",
            "nonBuildingRecords",
            "floorIds",
            "suiteIds",
            "categoryIds",
            "active",
            "@connection",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getEquipmentListGrouped: {
          keyArgs: [
            "search",
            "buildingIds",
            "nonBuildingRecords",
            "floorIds",
            "suiteIds",
            "categoryIds",
            "active",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getEquipmentCategories: {
          keyArgs: ["search", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getEquipmentTasks: {
          keyArgs: ["search", "equipmentId"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getEquipmentWorkOrders: {
          keyArgs: ["search", "equipmentId"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getEmployees: {
          keyArgs: ["buildingIds", "search", "active", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getPublicEmployees: {
          keyArgs: ["propertyId", "buildingIds", "search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getMaterials: {
          keyArgs: ["search", "active", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getMaterialsGrouped: {
          keyArgs: ["search", "active"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getMaterialGroups: {
          keyArgs: ["search", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCategories: {
          keyArgs: ["search", "recordType", "active", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCategoriesGrouped: {
          keyArgs: ["search", "recordType", "active"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getFilterableGroupedCategories: {
          keyArgs: ["search", "location"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCategoryGroups: {
          keyArgs: ["recordType", "search", "active", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getPublicCategoriesGrouped: {
          keyArgs: ["propertyId", "search", "recordType"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getSuites: {
          keyArgs: [
            "admin",
            "buildingIds",
            "floorId",
            "search",
            "types",
            "@connection",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getPublicSuites: {
          keyArgs: ["propertyId", "buildingIds", "floorId", "search", "types"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getInvoices: {
          keyArgs: ["search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getPayments: {
          keyArgs: ["search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getAmanoSettings: {
          keyArgs: ["search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getProcedures: {
          keyArgs: ["search", "active", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getSchedules: {
          keyArgs: ["buildingIds", "search", "recordStatuses", "from", "to"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getTasks: {
          keyArgs: [
            "buildingIds",
            "search",
            "status",
            "createdFrom",
            "createdTo",
            "completedFrom",
            "completedTo",
            "dueDateFrom",
            "dueDateTo",
            "createdByIds",
            "assignedToIds",
            "equipmentIds",
            "status",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getLeases: {
          keyArgs: ["search", "status"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getGeneaTenants: {
          keyArgs: ["search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getGeneaAccessGroups: {
          keyArgs: ["search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        populateCompanies: {
          keyArgs: ["ids", "search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getDeviceLogs: {
          keyArgs: ["deviceId", "search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getGuestWorkOrderForms: {
          keyArgs: ["buildingIds", "search", "active"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getMyNotifications: {
          keyArgs: ["notificationCategory"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
      },
    },
    RoomObject: {
      fields: {
        reservations: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    EmailTemplateObject: {
      keyFields: ["type"],
    },
    UserSettingNotificationObject: {
      keyFields: ["type"],
    },
    IntegrationListModel: {
      keyFields: ["type"],
    },
    IntegrationSetupModel: {
      keyFields: ["type"],
    },
    FileObject: {
      keyFields: false,
    },
    VendorAccessVisitModel: {
      keyFields: false,
    },
    GuestVisitModel: {
      keyFields: false,
    },
    ProductOnlyObject: {
      keyFields: false,
    },
    CouponProductsListObject: {
      keyFields: false,
    },
  },
});

export default cache;
